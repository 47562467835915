<template>
  <div class="page_modal course_modal" v-if="course">
    <div class="row back_button">
      <a class="back" href="#" @click.prevent="close">&#8592; Courses</a>
    </div>
    <header class="row">
      <div class="columns shrink facility_logo" v-if="facility.logo">
        <img :src="facility.logo" />
      </div>

      <div class="columns facility_name">
        <h2>
          {{ course.name }}
        </h2>
        {{ facility.city }}, {{ facility.state }} {{ facility.zipcode}}
      </div>
    </header>

    <div class="row">
      <div class="columns">
        <div class="item" v-if="facility.formerlyKnownAs">
          <label>Formerly known as</label>
          <div class="data">{{ facility.formerlyKnownAs }}</div>
        </div>
        <div class="item" v-if="facility.facilityType">
          <label>Facility type</label>
          <div class="data">{{ facility.facilityType }}</div>
        </div>

        <div class="item" v-if="facility.description">
          <label>Description</label>
          <div class="data">{{ facility.description}}</div>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="columns">

        <div class="item" v-if="facility.url">
          <label>Website</label>
          <div class="data"><a :href="facility.url" target="_blank">{{ truncate(facility.url, {length: 35}) }}</a></div>
        </div>

        <div class="item" v-if="facility.teeTimePhoneNumberFormatted">
          <label>Phone</label>
          <div class="data">{{ facility.teeTimePhoneNumberFormatted}}</div>
        </div>

        <div class="item" v-if="facility.generalManagerEmail">
          <label>Email</label>
          <div class="data"><a :href="`mailto:${facility.generalManagerEmail}`">{{ facility.generalManagerEmail}}</a></div>
        </div>
        <div class="item" v-if="facility.numberOfContacts != 0">
          <label>Number of contacts</label>
          <div class="data">{{ facility.numberOfContacts }}</div>
        </div>
      </div>
    </div>

    <div class="row extras courses" v-if="course">
     <div class="columns">
       <h3>Course Profile</h3>
         <h5 class="name">{{ course.name }}</h5>
         <div class="kind">{{ course.clubType }}<span v-if="course.numberOfHoles">, {{ course.numberOfHoles}} holes</span></div>
         <div class="description" v-if="course.description">{{ course.description }}</div>
         <div class="item playing_experience" v-if="course.playingExperience != 'None'">
           <label>Playing Experience</label>
           <div class="data">{{ course.playingExperience }}</div>
         </div>
         <div class="item year_opened" v-if="course.yearOpened">
           <label>Year Opened</label>
           <div class="data">{{ course.yearOpened }}</div>
         </div>
         <div class="item year_opened" v-if="course.yearClosed && course.clubType == 'Closed'">
           <label>Year Closed</label>
            <div class="data">{{ course.yearClosed }}</div>
         </div>

         <ul class="architects simple_list" v-if="course.architects.length">
           <h4>Architects</h4>
           <li v-for="architect in course.architects">
             {{ architect.name }}
           </li>
         </ul>
     </div>
    </div>

    <div class="row extras external_facilities" v-if="course && course.teeSetsOrdered.length">
     <div class="columns">
       <h3>Scorecard</h3>
       <table class="default scorecard">
         <thead>
          <th>Name</th>
          <th>Yardage</th>
          <th>Slope</th>
          <th>Rating</th>
          <th>Par</th>
         </thead>
         <tbody>
           <tr v-for="teeSet in course.teeSetsOrdered">
             <td>{{teeSet.name}}</td>
             <td>{{teeSet.yardage}}</td>
             <td>{{teeSet.slope}}</td>
             <td>{{teeSet.rating}}</td>
             <td>{{teeSet.par}}</td>
           </tr>
         </tbody>
       </table>
     </div>
    </div>

    <div class="row extras course_lists" v-if="course && course.courseLists.length">
     <div class="columns">
       <h3>Course Lists</h3>
       <ul class="course_lists simple_list">
       <li v-for="courseList in course.courseLists">
         <div><strong>{{courseList.name}}</strong></div>
         <div class="kind">{{ courseList.kind }}</div>
       </li>
       </ul>
     </div>
    </div>

  </div>
</template>

<script>

import axios from 'axios'
import truncate from 'lodash/truncate'
import { closeModal, pushModal } from 'jenesius-vue-modal'
import {configStore} from "../stores/config_store"

export default {
  props: [
    'initialCourseId',
    'modalCourseId'
  ],
  components: {},
  mounted() {
    this.truncate = truncate
    if(this.modalCourseId) {
      this.courseId = +this.modalCourseId
      this.fetchCourse()
      this.setPushState()
    }

    const store = configStore()
    this.userIgdb = store.user.igdb
  },
  data() {
    return {
      facility: null,
      course: null,
      loading: true,
      dataLoaded: false,
      courseId: null,
      userIgdb: false
    }
  },
  computed: {
  },
  watch: {
    courseId(newId) {
      if(newId) {
        this.fetchCourse()
      }
    }
  },
  methods: {
    fetchCourse() {
      this.loading = true

      axios.get(`/api/courses/${this.courseId}`).then((val) => {
        this.course = val.data
        this.facility = this.course.facility

        this.loading = false
       this.dataLoaded = true
      })
    },
    setPushState() {
      const path = `/courses/${this.courseId}`
      if(! this.initialCourseId && window.location.pathname !== path) {
        history.pushState({courseId: this.courseId}, "Course", `/courses/${this.courseId}`)
      }
    },
    goCourse(courseId) {
      this.setPushState()
    },
    close() {
      closeModal({namespace: 'page_modal'})
    }
  }
}
</script>