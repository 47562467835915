<template>
  <div class="page_modal facility_modal" v-if="facility">
    <div class="row back_button">
      <a class="back" href="#" @click.prevent="close">&#8592; Facilities</a>
    </div>
    <header class="row">
      <div class="columns shrink facility_logo" v-if="facility.logo">
        <img :src="facility.logo" />
      </div>

      <div class="columns facility_name">
        <h2>
          {{ facility.name }}
        </h2>
        {{ facility.city }}, {{ facility.state }} {{ facility.zipcode}}
      </div>
    </header>

    <div class="row">
      <div class="columns">
        <div class="item" v-if="facility.formerlyKnownAs">
          <label>Formerly known as</label>
          <div class="data">{{ facility.formerlyKnownAs }}</div>
        </div>
        <div class="item" v-if="facility.facilityType">
          <label>Facility type</label>
          <div class="data">{{ facility.facilityType }}</div>
        </div>

        <div class="item" v-if="facility.description">
          <label>Description</label>
          <div class="data">{{ facility.description}}</div>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="columns">

        <div class="item" v-if="facility.url">
          <label>Website</label>
          <div class="data"><a :href="facility.url" target="_blank">{{ truncate(facility.url, {length: 35}) }}</a></div>
        </div>

        <div class="item" v-if="facility.teeTimePhoneNumberFormatted">
          <label>Phone</label>
          <div class="data">{{ facility.teeTimePhoneNumberFormatted}}</div>
        </div>

        <div class="item" v-if="facility.generalManagerEmail">
          <label>Email</label>
          <div class="data"><a :href="`mailto:${facility.generalManagerEmail}`">{{ facility.generalManagerEmail}}</a></div>
        </div>
        <div class="item" v-if="facility.numberOfContacts != 0">
          <label>Number of contacts</label>
          <div class="data">{{ facility.numberOfContacts }}</div>
        </div>


        <div style="display: none">Name, Type, # of Holes, Playing Experience (courses will be selectable someday but not now</div>

      </div>
    </div>

    <div class="row extras courses" v-if="facility && facility.courses.length > 0">
     <div class="columns">
       <h3>Courses</h3>
       <div class="course" v-for="course in facility.courses">
         <h5 class="name">{{ course.name }}</h5>
         <div class="kind">{{ course.clubType }}<span v-if="course.numberOfHoles">, {{ course.numberOfHoles}} holes</span></div>
         <div class="playing_experience" v-if="course.playingExperience">{{ course.playingExperience }}</div>
       </div>
     </div>
    </div>

    <div class="row extras contacts" v-if="facility && facility.contacts.length > 0 && userIgdb">
     <div class="columns">
       <h3>Contacts</h3>
       <ul class="contacts">
       <li v-for="contact in facility.contacts" @click="openContact(contact)">
         <h5 class="name">{{ contact.fullName }}</h5>
         <div class="email" v-if="contact.email">{{ contact.email }}</div>
         </li>
       </ul>
     </div>
    </div>

    <div class="row extras external_facilities" v-if="facility && facility.externalFacilities.length > 0 && userIgdb">
     <div class="columns">
       <h3>External Facilities</h3>
       <ul class="external_facilities">
       <li v-for="fac in facility.externalFacilities">
         <h5 class="kind">{{ fac.kindFormatted }}: <span class="id"> id {{ fac.externalId }}</span></h5>

         </li>
       </ul>
     </div>
    </div>

    <div class="row extras network_affiliations" v-if="facility && facility.affiliatedNetworks.length > 0 && userIgdb">
     <div class="columns">
       <h3>Network Affiliations</h3>
       <ul class="network_affiliations">
       <li v-for="network in facility.affiliatedNetworks">
         <div class="kind">{{ network.name }}</div>
         </li>
       </ul>
     </div>
    </div>

  </div>
</template>

<script>

import axios from 'axios'
import truncate from 'lodash/truncate'
import { closeModal, pushModal } from 'jenesius-vue-modal'
import ContactModal from '../components/contact_modal.vue'
import {configStore} from "../stores/config_store"

export default {
  props: [
    'initialFacilityId',
    'modalFacilityId'
  ],
  components: {ContactModal},
  mounted() {
    this.truncate = truncate
    if(this.modalFacilityId) {
      this.facilityId = +this.modalFacilityId
      this.fetchFacility()
      this.setPushState()
    }

    const store = configStore()
    this.userIgdb = store.user.igdb
  },
  data() {
    return {
      facility: null,
      loading: true,
      dataLoaded: false,
      facilityId: null,
      userIgdb: false
    }
  },
  computed: {
    index() {
      return this.$store.state.propertyIndex
    },
    totalCount() {
      return this.$store.state.propertyTotalCount
    },
    tabs() {
      return this.defaultTabs
    },
    initialTab() {
      return this.tabs[0]
    },
    showNext() {
      return this.index + 1 <= this.totalCount
    },
    showPrev() {
      return this.index > 0
    }
  },
  watch: {
    facilityId(newId) {
      if(newId) {
        this.fetchFacility()
      }
    }
  },
  methods: {
    fetchFacility() {
      this.loading = true

      axios.get(`/api/facilities/${this.facilityId}`).then((val) => {
        this.facility = val.data

        this.loading = false
       this.dataLoaded = true
      })
    },
    setPushState() {
      const path = `/facilities/${this.facilityId}`
      if(! this.initialFacilityId && window.location.pathname !== path) {
        history.pushState({facilityId: this.facilityId}, "Facility", `/facilities/${this.facilityId}`)
      }
    },
    goFacility(facilityId) {
      this.setPushState()
    },
    close() {
      closeModal({namespace: 'page_modal'})
    },
    openContact(contact) {
      pushModal(ContactModal, {contact: contact})
    }
  }
}
</script>