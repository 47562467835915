<template>
  <div class="search_listing">
    <div class="row">
      <div class="columns">
        <div class="search">
          <div>
            <label class="label">Search Facilities</label>
            <input class="full_search" placeholder="Type to search names" type="search" v-model="search" />
          </div>
          <div v-if="userIgdb">
            <label class="label">Facility type</label>
            <select v-model="filters.facility_type">
              <option value="">Any</option>
              <option v-for="kind in facilityTypes" :value="kind[1]">{{ kind[0]}}</option>
            </select>
          </div>
          <div v-if="userIgdb">
            <label class="label">Location</label>
            <input type="search" placeholder="Search city/state" v-model="filters.location">
          </div>
        </div>
        <DataTable class="facility_listing" :loading="loading" :columns="columns" :data="facilities" @open="openFacility" @filter="setColumnFilter" :activeColumns="activeColumns">
          <template v-slot:name="slotProps">
            <strong>{{ slotProps.object.name }}</strong>
            <span>{{ slotProps.object.cityState }}</span>
          </template>

        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>

import DataTable from '../components/data_table.vue'
import {showModal} from '../components/modal_wrapper'
import FacilityModal from '../components/facility_modal.vue'
import ScrollLoaderMixin from '../modules/scroll_loader_mixin'
import {removeEmpty} from '../modules/util'

import debounce from 'lodash/debounce'
import pickBy from 'lodash/pickBy'
import axios from 'axios'
import {closeModal} from "jenesius-vue-modal"
import {configStore} from "../stores/config_store"

export default {
  name: 'FacilitiesList',
  mixins: [ScrollLoaderMixin],
  components: {DataTable},
  created() {
    this.searchDebounced = debounce( (search, filters) => {
      this.runSearch(search, filters)
    }, 300) // End debounce
  },
  mounted() {
    this.setupInteractionObserver()
    if(this.$route.params.facilityId) {
      this.facilityId = this.$route.params.facilityId
      this.openFacility(this.facilityId, true)
    }
    const store = configStore()
    this.userIgdb = store.user.igdb
    this.facilityTypes = store.facilityTypes
  },
  data() {
    return {
      loading: false,
      facilities: [],
      search: '',
      page: 1,
      totalPages: 1,
      facilityId: null,
      filters: {},
      userIgdb: false,
      columnFilter: {},
      facilityTypes: [],
      columns: {
         name: {name: 'Name', sortable: false, filterable: false},
         facilityType: {name: 'Type', sortable: false, filterable: false}
       }
    }
  },
  computed: {
    activeColumns() {
      let columns = ['name', 'facilityType']
      return columns
    }
  },
  watch: {
    search(val) {
      if(val.length > 2) {
        this.searchDebounced(val, this.filters)
      }
      else {
        this.facilities = []
        this.totalPages = 0
      }
    },
    'filters': {
      deep: true,
      handler(val) {
        this.searchDebounced(this.search, this.filters)
      }
    },
    $route(to, from) {
      if(this.$route.params.facilityId && +this.$route.params.facilityId !== this.facilityId) {
        this.facilityId = +this.$route.params.facilityId
        this.openFacility(+this.$route.params.facilityId, true)
      }
      else if(! this.$route.params.facilityId && this.facilityId) {
        this.facilityId = null
        this.closeFacilityModal()
      }
    }
  },
  methods: {
    runSearch(search = '', columnFilter = {}, deleteFirst = true) {

      let filter = this.getFilterParams(search, columnFilter)
      let runSearch = (search === this.search && this.search.length > 2) || Object.keys(columnFilter).length > 0
      if(runSearch) {
        this.loading = true
        axios.get(`/api/facilities`, {params: filter}).then((val) => {
          if(deleteFirst) {
            this.facilities = val.data
          }
          else {
            this.facilities = this.facilities.concat(val.data)
          }
          this.totalPages = val.headers['total-pages']
          this.loading = false
        })
      }
    },
    fetchNewPage() {
      if(this.page <= 5) {
        this.runSearch(this.search, this.columnFilter, false)
      }
      else {
        self.page--;
      }
    },
    getFilterParams(search = '', filters = {}) {
      let filter = removeEmpty({...filters})
      filter.page = this.page
      if(search.length > 2) {
        filter.q = search
      }
      return filter
    },
    setColumnFilter(filters) {
      this.page = 1
      this.columnFilter = pickBy(filters, (val) => !! val)
      this.searchDebounced(this.search, this.columnFilter)
    },
    openFacility(id, index = null) {
      this.facilityId = id
      this.$nextTick(() => {
        this.openFacilityModal(id, true)
      })
    },
    openFacilityModal(id, returnToList = false) {
      // TODO handle small viewport

      function beforeModalClose() {
        if(returnToList && window.location.pathname !== '/facilities') {
          history.pushState({facilities: true}, "Facilities", '/facilities')
        }
      }
      showModal(FacilityModal,
        { modalFacilityId: id},
        { beforeClose: beforeModalClose.bind(this), escClose: false, namespace: 'page_modal'}
      )
    },
    closeFacilityModal() {
      closeModal({namespace: 'page_modal'})
    }
  }
}
</script>