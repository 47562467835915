<template>
  <div class="search_listing">
    <div class="row">
      <div class="columns">
        <div class="search">
          <div>
            <label class="label">Search Contacts</label>
            <input class="full_search" placeholder="Type to search names" type="search" v-model="search" />
          </div>
        </div>
        <DataTable class="contact_listing" :loading="loading" :columns="columns" :data="contacts" @open="openContact" @filter="setColumnFilter" :activeColumns="activeColumns">
          <template v-slot:name="slotProps">
            <strong>{{ slotProps.object.name }}</strong>
            <span>{{ slotProps.object.cityState }}</span>
          </template>

        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>

import DataTable from '../components/data_table.vue'
import {showModal} from '../components/modal_wrapper'
import ContactFullModal from '../components/contact_full_modal.vue'
import ScrollLoaderMixin from '../modules/scroll_loader_mixin'
import {removeEmpty} from '../modules/util'

import debounce from 'lodash/debounce'
import pickBy from 'lodash/pickBy'
import axios from 'axios'
import {closeModal} from "jenesius-vue-modal"
import {configStore} from "../stores/config_store"

export default {
  name: 'ContactsList',
  mixins: [ScrollLoaderMixin],
  components: {DataTable},

  created() {
    this.searchDebounced = debounce( (search, filters) => {
      this.runSearch(search, filters)
    }, 300) // End debounce
  },
  mounted() {
    this.setupInteractionObserver()
    if(this.$route.params.contactId) {
      this.contactId = this.$route.params.contactId
      this.openContact(this.contactId, true)
    }
    const store = configStore()
    this.userIgdb = store.user.igdb
  },
  data() {
    return {
      loading: false,
      contacts: [],
      search: '',
      page: 1,
      totalPages: 1,
      contactId: null,
      filters: {},
      userIgdb: false,
      columnFilter: {},
      columns: {
         fullName: {name: 'Name', sortable: false, filterable: false}
         // facilityType: {name: 'Type', sortable: false, filterable: false}
       }
    }
  },
  computed: {
    activeColumns() {
      let columns = ['fullName']
      return columns
    }
  },
  watch: {
    search(val) {
      if(val.length > 2) {
        this.searchDebounced(val, this.filters)
      }
      else {
        this.contacts = []
        this.totalPages = 0
      }
    },
    'filters': {
      deep: true,
      handler(val) {
        this.searchDebounced(this.search, this.filters)
      }
    },
    $route(to, from) {
      if(this.$route.params.contactId && +this.$route.params.contactId !== this.contactId) {
        this.contactId = +this.$route.params.contactId
        this.openContact(+this.$route.params.contactId, true)
      }
      else if(! this.$route.params.contactId && this.contactId) {
        this.contactId = null
        this.closeContactModal()
      }
    }
  },
  methods: {
    runSearch(search = '', columnFilter = {}, deleteFirst = true) {

      let filter = this.getFilterParams(search, columnFilter)
      let runSearch = (search === this.search && this.search.length > 2) || Object.keys(columnFilter).length > 0
      if(runSearch) {
        this.loading = true
        axios.get(`/api/contacts`, {params: filter}).then((val) => {
          if(deleteFirst) {
            this.contacts = val.data
          }
          else {
            this.contacts = this.contacts.concat(val.data)
          }
          this.totalPages = val.headers['total-pages']
          this.loading = false
        })
      }
    },
    fetchNewPage() {
      if(this.page <= 5) {
        this.runSearch(this.search, this.columnFilter, false)
      }
      else {
        self.page--;
      }
    },
    getFilterParams(search = '', filters = {}) {
      let filter = removeEmpty({...filters})
      filter.page = this.page
      if(search.length > 2) {
        filter.q = search
      }
      return filter
    },
    setColumnFilter(filters) {
      this.page = 1
      this.columnFilter = pickBy(filters, (val) => !! val)
      this.searchDebounced(this.search, this.columnFilter)
    },
    openContact(id, index = null) {
      this.contactId = id
      this.$nextTick(() => {
        this.openContactModal(id, true)
      })
    },
    openContactModal(id, returnToList = false) {
      // TODO handle small viewport

      function beforeModalClose() {
        if(returnToList && window.location.pathname !== '/contacts') {
          history.pushState({contacts: true}, "Contacts", '/contacts')
        }
      }
      showModal(ContactFullModal,
        { modalContactId: id},
        { beforeClose: beforeModalClose.bind(this), escClose: false, namespace: 'page_modal'}
      )
    },
    closeContactModal() {
      closeModal({namespace: 'page_modal'})
    }
  }
}
</script>